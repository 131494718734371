@import '~antd/es/style/themes/default.less';

html, body, #root, #App {
	height: 100%;
}

body
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  content: none;
}

body
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::after,
.required-mark {
  display: inline-block;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-card {
  margin-bottom: 15px;
}

// .ant-card-head-title .ant-btn {
//   margin-left: 10px;
// }

.drag-handle {
  width: 32px;
  height: 32px;
  background-color: #f1f1f1;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-handle .anticon {
  color: #777;
}

.care-guide-form .nested-list,
.care-guide-section-form .nested-list {
  margin-left: 40px;
}

.care-guide-segment-spacer .ant-space-item:nth-of-type(2) {
  width: 100%;
}

.care-guide-form .quill,
.care-guide-section-form .quill {
  margin-bottom: 24px;
}

.care-guide-form .field-type-table-column-list,
.care-guide-section-form .field-type-table-column-list {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.care-guide-form .field-type-table-column-list > .ant-space,
.care-guide-section-form .field-type-table-column-list > .ant-space {
  margin-right: 8px;
}

.care-guide-form .field-type-table-column-list .ant-form-item,
.care-guide-section-form .field-type-table-column-list .ant-form-item {
  margin-bottom: 0;
}

.care-guide-form .field-type-table-row-list .ant-space,
.care-guide-section-form .field-type-table-row-list .ant-space {
  display: flex;
  flex-direction: row;
}

.care-guide-form .field-type-table-row-list .ant-form-item,
.care-guide-section-form .field-type-table-row-list .ant-form-item {
  margin-bottom: 8px;
}

#message_form .recipient-input-row .ant-select-selector,
#calendar_event_form .participant-input-row .ant-select-selector,
#planner_form .helper-input-row .ant-select-selector {
  width: 150px;
}


.form-group.checkbox-list .ant-checkbox-group-item {
  display: flex;
}

.calendar-event-form .ant-picker {
  width: 100%;
}

.recipient-input-row .ant-form-item{
  width: 400px;
}

// screen transitions

// .screen-fade-enter {
//   opacity: 0;
//   height: 0;
// }

// .screen-fade-enter-active {
//   opacity: 1;
//   transition: opacity 250ms;
//   transition-delay: 150ms;
// }

// .screen-fade-exit {
//   opacity: 1;
// }

// .screen-fade-exit-active {
//   opacity: 0;
//   transform: translateY(10px);
//   transition: opacity 150ms, transform 150ms;
// }

.scroll-participants{
  max-height: 170px;
  overflow-y: scroll;
}

.react-tel-input {
	font-size: 0.9rem !important;
}

.react-tel-input .form-control {
	width: 100% !important;
} 

#planner_to_do_form .value-answer-input-row .ant-select-selector {
  width: 200px;
}

.note-input-row-body {
  width: 500px;
}

.note-input-row-select {
  width: 200px;
}

.recipient-input-row .ant-form-item{
  width: 400px;
}
.body-input .ql-container.ql-snow {
  height: auto;
}

.body-input .ql-editor {
  height: 300px;
  overflow-y: scroll;
}

.calendar-participants{
  height: 210px;
  overflow-y: auto;
  margin-bottom: 8px;
}

.calendar-participants table td:last-child {
  color: rgba(0, 0, 0, 0.45);
}

.export-care-guides-options-title {
  font-size: 18px !important;
}


